/*:root{
  
}*/
.App{
  overflow-x: hidden;
}
body{
  font-family: 'Roboto', sans-serif !important;
  color: #333;
  font-size: 14px;
  line-height: 1.8;
}
a:hover{
  outline: none;
  color: inherit;
}
a{
  color: #222;
}
p{
  font-weight: 400;
  line-height: 25px;
}
/* images */
.col-padding-image{
  padding: 0 3px;
}
img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.brand {
  height: 95px;
}
.w-nav-brand {
  float: left;
  text-decoration: none;
  color: #333;
}
.text-blue{
  color:#2da1f8;
}
.col-no-padding{
  padding-left: 0;
  padding-right: 0;
}
.text-gray-collect{
  color:#2f3439
}

/* text */

.heading {
  font-size: 70px;
  line-height: 1;
  margin-bottom: 0;
}
.no-margin{

}
.bg-safe-1.py-section{
  padding-bottom: 0;
}
.shield-circle{
    display: flex;
    width: 250px;
    height: 250px;
    margin-bottom: -61px;
    padding: 4vw;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 50px 1px hsl(0deg 0% 100% / 46%);
}
.heading-text{
  max-width: 320px;
  margin-top: 36px;
  padding-right: 61px;
  font-size: 22px;
  line-height: 1.7;
  font-weight: 400;
}
.icon-and-description-container {
  margin-bottom: 52px;
  color: #fff;
}
.feature-icon {
  width: 100%;
  height: 100%;
  margin-bottom: 7px;
}
.feature-icon-container {
    height: 50px;
    margin-bottom: 14px;
}
.feature-headline {
  margin-bottom: 3px;
  font-size: 19px;
}
.feature-description {
  opacity: .44;
  font-size: 15px;
  font-weight: 400;
}
.opacity-44{
  opacity: .44;
}
.icon-container{
  width: 45px;
    margin-right: 21px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
} 
.text-shadow.white{
  color: #fff;
    text-align: center;
    text-shadow: 0 1px 6px rgb(81 97 115 / 64%);
}

.heading-3 {
  font-size: 32px;
  font-weight: 500;
}
.paragraph {
  font-weight: 300;
  text-shadow: 1px 1px 6px #000;
}
.paragraph-2 {
  color: #fff;
  font-weight: 400;
}
.paragraph-2.grey{
  color: #a7a7a7;
}

.abs-phone.header {
  padding-right: 55px;
}

.header {
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  color: #fff;
  font-size: 65px;
  line-height: 1;
  font-weight: 500;
}
.header-blue{
  color: #2da1f8;
}

.container{
  max-width: 940px!important;
}
.w-nav-link {
    margin-right: 0;
    margin-left: 0;
    padding-right: 22px;
    padding-left: 22px;
    font-size: 16px;
    display: inline-block;
    color: #222;
    padding: 20px;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
}



.heading, body {
    font-weight: 500;
}


.header {
    margin-right: auto;
    margin-bottom: 40px;
    margin-left: auto;
    color: #fff;
    font-size: 65px;
    line-height: 1;
    font-weight: 500;
}
.header.dark {
    color: #516173;
}

.title {
    color: #fff;
    font-size: 25px;
    text-align: left;
    text-transform: uppercase;
}
.title.new {
  color: #2da1f8;
  text-transform: uppercase;
}
/* become driver */
.bd-card{
  padding: 27px;
  background-color: #2da1f8;
}
.bd-card-title{
  font-size: 16px;
  font-weight: 700;
}
.bd-card-description{
  opacity: .63;
  color: #fff;
  font-weight: 400;
}
.bd-path{
  width: 60px;
  height: 100%;
  background-image: url(./assets/dotted-connector.svg);
  background-position: 50% 50%;
  background-size: 125px;
  background-repeat: no-repeat;
}
.bd-path.flipped{
  background-image: url(./assets/dotted-connector-flipped.svg);
}
.col-bd{
  padding-left: 0;
  padding-right: 0;
}
.hide-overflow{
  overflow: hidden;
}
/* HOME */
.bg-photo{
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;  
  background-image: url("./assets/bg_home.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: .09;
}

.py-section{
  padding-top: 111px;
  padding-bottom: 111px;
}
.py-section.section-features{
  padding-top: 58px;
  padding-bottom: 0;
}

/* Features */
.section.downloads-hero {
    z-index: 5;    
    background-image: url('./assets/features/car-crash.svg'),linear-gradient(350deg,#516173,#7f8fa2);
    background-position: 100% 8%,0 0;
    background-size: 410px,auto;
    background-repeat: no-repeat,repeat;
}

.feature-icon {
    width: 100%;
    height: 100%;
    margin-bottom: 7px;
}

img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}
.div-block-6 {
    margin-top: 9px;
}
.divider{
  height: 1px;
  width: 100%;
}

.connector {
    width: 6%;
    background-image: url(https://assets.website-files.com/5c5c602…/5e6bb90…_dotted-connector.svg);
    background-position: 50% 50%;
    background-size: 125px;
    background-repeat: no-repeat;
}
.row-tutorial{
  padding-right: 89px!important;
  padding-left: 89px!important;
}
.arrow, .btm-blue-div {
  background-color: #2da1f8;
}
.arrow {
  position: absolute;
  left: 0;
  top: 46%;
  width: 28px;
  height: 28px;
  margin-left: -13px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.btm-blue-div {

    position: relative;
    z-index: 5;
    display: flex;
    width: 100%;
    height: 80%;    
    padding: 40px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.abs-phone {
    position: absolute;
    padding-right: 0;
    padding-left: 0;
}

.phone-container.cutoff.driver-tut01 {
    background-image: url(https://assets.website-files.com/5c5c602…/5c65f04…_driver-tut-01.png);
}
.phone-container.cutoff.driver-tut01, .phone-container.cutoff.driver-tut02, .phone-container.cutoff.driver-tut03, .phone-container.cutoff.driver-tut04, .phone-container.cutoff.driver-tut05, .phone-container.cutoff.pass-tut01, .phone-container.cutoff.pass-tut02, .phone-container.cutoff.pass-tut03, .phone-container.cutoff.pass-tut04, .phone-container.cutoff.pass-tut05, .phone-container.cutoff.pass-tut07 {
    background-position: 50% 100%;
    background-size: contain;
    background-repeat: no-repeat;
}

/* How it works */
.section.howitworks {
    z-index: 15;
    background-color: #fff;
    box-shadow: 0 -1px 25px 0 rgb(0 0 0 / 32%);
}

/* Keep safe */

.section.keepingyousafe {
    z-index: 55;
}
.bg-pic {
    position: absolute;
    left: 0;
    top: 0;
    background-image: radial-gradient(circle closest-corner at 50% 100%,rgba(175,216,251,.89),rgba(45,161,248,.89)),url("./assets//safe/background.jpg");
    background-position: 0 0,50% 0;
    background-size: auto,cover;
    background-repeat: repeat,no-repeat;
    opacity: 1;
    -webkit-filter: none;
    filter: none;
    z-index: -1;
}

.section.downloads-btm {
    background-image: linear-gradient(350deg,#516173,#7f8fa2);
    background-position: 0 0,0 0;
    background-size: auto,125px;
    background-repeat: repeat,no-repeat;
}

.section.contact {
    background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.5)),to(rgba(0,0,0,.5))),url('/src/assets/bg_contacto.jpg');
    background-image: linear-gradient(180deg,rgba(0,0,0,.5),rgba(0,0,0,.5)),url('/src/assets/bg_contacto.jpg');
    background-position: 0 0,50% 50%;
    background-size: auto,cover;
    background-repeat: repeat,no-repeat;
}


/* Contact */
.header.dark {
    color: #516173;
}
.form-block {
    color: #fff;
}

.w-form {
    margin: 0 0 15px;
}

.form-container {
    width: 60%;
    margin-right: auto;
    margin-bottom: 51px;
    margin-left: auto;
    padding: 57px;
    background-color: #fff;
}
.w-input, .w-select {
	display: block;
	width: 100%;
	height: 38px;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #333;
	vertical-align: middle;
	background-color: #fff;
	border: 1px solid #ccc;
}

.w-select[multiple], textarea.w-input, textarea.w-select {
    height: auto;
}
.form-element-container {
    margin-bottom: 16px;
    padding: 11px;
    border-radius: 4px;
    background-color: hsla(0,0%,69.4%,.11);
}

.text-field-2 {
  margin-bottom: 0;
  border-style: none;
  background-color: transparent;
  opacity: 1;
  color: #516173;
  font-weight: 400;
}
.text-field-2.message {
  height: 158px;
}
.w-select{
  background-color: #f3f3f3;
}

select:focus-visible,input:focus-visible,textarea:focus-visible{
  outline: none;
}

.button.contact {
    width: 50%;
    padding: 10px 54px;
    float: right;
    border-radius: 41px;
    font-size: 19px;
    font-weight: 500;
    text-transform: none;
}

.button, .button.contact {
    background-color: #516173;
    box-shadow: none;
    color: #fff;
}
input.w-button {
    -webkit-appearance: button;
}
.w-button{
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898EC;
  color: #fff;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

.prize-card{
  border-radius: 1rem;
  box-shadow: 0px 0px 7px -1px #80808057;
  overflow: hidden;
}
.prize-card-header{
  background-color:#2da1f8;;  
  padding: 1rem;
  position: relative;
}
.prize-card-header-title{
  color: white;
}
.text-clear-blue{
  color: #3492bd;
}
.prize-card{
  background-color: white;
}
.prize-card-prize{
  color: white;
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  left: calc(50% - 40px);
  bottom: -40px;
  background-color: #2da1f8;
  padding: 0.5rem;
  border: 1px solid white;
}
.prize-card-prize h6,.prize-card-prize p{
  margin-bottom: 0;
}
.prize-card-body{
  color: #74818d;
  height: 400px;
  padding: 1rem;  
}

.prize-card-check{
  width: 60px;
  height: 60px;
}

.check-img{
  width: 30px;
  height: 30px;    
}
.check-img img{
  width: 100%;
  height: 100%;
}

.no-margin{
  margin: 0;
}

.toggle-container {
  position: static;
  display: block;
  width: 100px;
  margin: -55px auto 47px;
  padding: 9px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 41px;
  background-color: #fff;
}

.toggle-container.right {
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.toggle-switch {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #4a90e2;
}
.tab-padding.left {
  padding-right: 80px;
  padding-left: 21px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.toggle-container {
  position: static;
  display: block;
  width: 100px;
  margin: -68px auto 47px;
  padding: 9px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 41px;
  background-color: #fff;
}
.toggle-container.new {
  background-color: #2da1f8;
}
.toggle-switch.new {
  background-color: #fff;
}
.cursor-pointer{
  cursor: pointer;
}
.opacity-6{
  opacity: .6;
}
.tutorial-card{
  
}
.turotial-dots{

}
.hero-padding-top{
  padding-top: 51px;
}
.tutorial-button-container{
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  z-index: 99;
  cursor: pointer;
}
.tutorial-button-container.left{
  left: -7%;
}
.tutorial-button-container.right{
  right: -11%;
}
.tutorial-button{
  cursor: pointer;
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  color: black;
  opacity: .9;  
  box-shadow: 0px 0px 7px -1px #80808057;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tutorial-button.dot{
  width: 1rem;
  height: 1rem;
  border-radius: 10px;  
}
.tutorial-button.active{
  opacity:1!important;
  box-shadow: 0px 0px 10px -3px black;
}
.fixed-button{
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 555;
    display: flex;
    width: 50px;
    height: 50px;
    margin-right: 44px;
    margin-bottom: 88px;
    padding: 13px;
    t-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;  
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 17%);
    cursor: pointer;
  
}
.tutorial-dots{
  position: absolute;
  bottom: 10px;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
.col-contact{
  width: 60%;
  margin-right: auto;
  margin-bottom: 51px;
  margin-left: auto;
  padding: 57px;
  background-color: #fff;
}

.a-white,.a-white:focus-visible{
  color: white!important;
  outline: none;
}

.menu-button{
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.menu-button.active{
  z-index: 999999;
   background-color: #516173;
}

.mobile-menu{
  position: absolute;
  top: 155px;
  left: 5%;
  right: 5%;
  padding: 1rem;
  z-index: 99;
  background-color: white;
  box-shadow: 0px 7px 7px -1px grey;
}
.z-9999{
  z-index: 9999;
}
.close-btn {
  left: auto;
  bottom: 0;
  z-index: 99999;
  width: 60px;
  height: 60px;
  margin-top: 5%;
  margin-right: 5%;
  background-color: #828e99;
  background-image: url('./assets/cross-icon.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
}

.close-btn{
  position: fixed;
  top: 0;
  right: 0;
}
.close-btn {
  border-radius: 50%;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
.bg-gray{
  background-color:#718194;
}

.mb-md-extra-11 {
  margin-bottom: 11rem!important;
}

.link-design{
  display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: 29px;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: none;
}
.link-design:hover{
  opacity: .55;
}
.link-design-by{
  display: inline-block;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
}
.link-design-icon{
  margin-right: 8px;
  margin-left: 8px;
}
.link-desing-adrial{
  display: inline-block;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
}

.modal-download-container{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: transparent;
  box-shadow: 0px 0px 7px -1px #80808057;
}
.modal-download{
  background-color: white;
  position: relative;
  box-shadow: 0 0 7px -1px grey;
}
.modal-download-body{
  padding: 3rem;
}
.modal-download-close{
  height: 16px;
  width: 16px;
  position: absolute;
  right: 2px;
  top: 2px;
  cursor: pointer;
}
.form-email{
  display: flex;
    margin-top: 19px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.form-email-body{
    display: flex;
    width: 80%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.form-email-field-wrapper{
    width: 70%;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #f5f4f4;
}
.form-email-submit-container{
  display: flex;
  width: 60%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.form-email-input{
  margin-bottom: 0;
  border: 1px #000;
  background-color: transparent;
  text-align: center;
}
.form-email-submit{
  display: block;
  margin-top: 18px;
  margin-bottom: 18px;
  margin-left: auto;
  margin-right: auto;
  padding: 2px 17px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
  background-color: #000;
  font-size: 1.25rem;
  font-weight: 400;  
  transition: all .2s ease;
  color: #fff;
}
@media screen and (max-width: 950px){
  .button.contact{
    padding: 10px 10px;
  }
  .btm-blue-div {    
    padding: 10px;
  }
}
@media screen and (max-width: 767px){
  /* todo:fix blue div on steps */  
  .btm-blue-div{

  }
  .prize-card-prize {  
      width: 100px;
      height: 100px;     
      left: calc(50% - 50px);
      bottom: -75px;
      border-radius: 50px;
      margin-top: 1rem;
  }
  .mb-md-extra-11 {
    margin-bottom: 2rem!important;
  }
  .button.contact {
    display: block;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    float: none;
}
  .col-contact{
    width: 95%;
    padding: 29px;
  }
  .phone-container{
    display: block;
    width: 65%;
    margin-bottom: -48px;
  }
  .heading-text{
    margin-left: auto;
    margin-right: auto;
    max-width: 440px;
    padding: 0;
  }
  .abs-phone {
    position: static;
    padding-left: 55px;
    padding-right: 55px;
  }
  .toggle-switch {
      width: 20px;
      height: 20px;
  }
  .toggle-container {
      width: 80px;
      height: 38px;
      margin-top: -60px;
  }
  .arrow {    
    left: 50%;
    top: -13px;
  }
  .bd-path{
    height: 60px;
    transform: rotate(90deg);
  }
  .toggle-container {
    width: 60px;
    margin-bottom: 118px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.toggle-container, .toggle-container.new {
    height: 32px;
    margin-top: -155px;
    padding: 6px;
}
.row-tutorial {
  padding-right: 2rem!important;
  padding-left: 2rem!important;
}
}
@media screen and (max-width: 479px){    
  .icon-container {
    width: 50px;
    margin-right: 0;    
    padding-top: 10px;
  }
  .form-email-submit-container{
    width: 100%;
  }
  .tutorial-button-container.left{
    left: -9%;
  }
  .tutorial-button-container.right{
    right: -14%;
  }
  .heading,.header {    
    font-size: 32px;
  }
  .heading-3{
    font-size: 26px;
  }
  .heading-text{
    font-size: 14px;
  }
}
